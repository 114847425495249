import { computed } from 'vue';
import { useRoute } from 'vue-router';
import seoSchema from './seoSchema';
import { useGlobalStore } from '~/stores/global';
import { useRuntimeConfig } from 'nuxt/app';

const useSeoSchema = (
	params?: string,
	title?: string,
	description?: string,
	productImg?: string,
) => {
	const route = useRoute();
	const { storeType } = useGlobalStore();
	const SeoData = computed(() => {
		switch (params) {
			case 'homepage':
				return seoSchema.webpage;
			case 'women':
				return seoSchema.women;
			case 'men':
				return seoSchema.men;
			case 'kids':
				return seoSchema.kids;
			case 'products':
				return { name: title, description: description, image: '' };
			default:
				return seoSchema.webpage;
		}
	});
	if (
		useRuntimeConfig().public.env === 'production' &&
		storeType !== 'OCTANE'
	) {
		useHead({
			script: [
				{
					type: 'application/ld+json',
					children: JSON.stringify({
						'@context': 'https://schema.org',
						'@type': 'WebPage',
						'@id': `https://laam.pk/${route.fullPath}`,
						name: SeoData.value.name,
						url: `https://laam.pk${route.path}`,
						description: SeoData.value.description,
						image: {
							'@type': 'ImageObject',
							url: params === 'products' ? productImg : SeoData.value.image,
						},
						publisher: {
							'@type': 'Organization',
							name: 'Laam',
							logo: {
								'@type': 'ImageObject',
								url: 'https://cdn.shopify.com/s/files/1/2337/7003/files/laam_logo_svg.svg?v=1738576396',
							},
						},
					}),
				},
			],
		});
	}
};

export default useSeoSchema;
